<template lang="html">
	<article class="step">
		<h2>{{ $t(`${sector}.a1_2.title`) }}</h2>
		<p>{{ $t(`${sector}.a1_2.desc`) }}</p>
		<step-fuels :sector="sector" abast="a1" :valid.sync="valid.a1fixes" required />
		<step-mobils :sector="sector" abast="a1" :valid.sync="valid.a1mobils" required />
		<step-energy :sector="sector" abast="a2" :valid.sync="valid.a2mobils" required />
	</article>
</template>

<script>
import StepMixin from '@/mixins/StepMixin';
import StepFuels from '../blocks/StepFuels.vue';
import StepMobils from '../blocks/StepMobils.vue';
import StepEnergy from '../blocks/StepEnergy.vue';

export default {
	name: 'StepA1_2',
	mixins: [StepMixin],
	components: { StepFuels, StepMobils, StepEnergy },
};
</script>
